import { useState } from 'react';

import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import pLimit from "p-limit";

import { Box } from '@mui/system';

import { styled } from '@mui/system';
import { Button } from '@mui/material';

const Container = styled(Box)`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    height: 30vh;
  `;

// ----------------------------------------------------------------------
// const worker_endpoint = "http://localhost:8787"
const worker_endpoint = "https://hono-r2.kirklayer6590.workers.dev"

export default function PageOne() {


    return (
        <>




            <h1>Kioskloud Minimal</h1>
            <Basic />

        </>
    );
}



function Basic(props) {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ maxFiles: 1 });

    const [downloadURL, setDownloadURL] = useState("")

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    async function upload() {
        const file = acceptedFiles[0]
        console.log(file)

        const uploadSpeed = {
            currentUploadSize: 0,
            currentLoadedSize: 0,
            pastSize: 0,
            sizeDelta: 0,
            currentSpeed: 1,
        }
        // const worker_endpoint = "https://myworker.myzone.workers.dev/"

        //  Configure the part size to be 100MB. 5MB is the minimum part size, except for the last part
        // const partsize = 100 * 1024 * 1024

        // const part_count = Math.ceil(file.size / partsize)
        // console.log("part_count", part_count)



        const key = crypto.randomUUID();
        console.log(key)

        const result = await axios.post(`${worker_endpoint}/api/mpu-create/${key}`)
        console.log(result)
        // uploadId 에 / 이런게 들어가서 , encodeURIComponent 해줘야함,  backend 에서 다시 decodeUriComponent 해줌
        const uploadId = encodeURIComponent(result.data.uploadId);


        const chunkSize = 100 * 1024 * 1024
        const chunks = [];
        let startPointer = 0;
        const endPointer = file.size;
        while (startPointer < endPointer) {
            const newStartPointer = startPointer + chunkSize;
            chunks.push(file.slice(startPointer, newStartPointer));
            startPointer = newStartPointer;
        }
        console.log(chunks)

        const uploadChunkV2PromiseList = []
        const limit = pLimit(1)
        for (let i = 0; i < chunks.length; i += 1) {
            uploadChunkV2PromiseList.push(limit(() => uploadChunkV2(key, uploadId, chunks[i], i + 1, uploadSpeed)))
        }

        const uploadChunkV2ResultList = await Promise.all(uploadChunkV2PromiseList)
        console.log(uploadChunkV2ResultList)



        const completeResult = await axios.post(`${worker_endpoint}/api/mpu-complete/${key}/${uploadId}`, {
            parts: uploadChunkV2ResultList
        })

        console.log(completeResult)

        setDownloadURL(`${worker_endpoint}/api/mpu-get/${key}`)

        return completeResult


    }

    async function uploadChunkV2(key, uploadId, blob, partNumber, uploadSpeed) {




        const result = await axios.put(`${worker_endpoint}/api/mpu-uploadpart/${key}/${uploadId}/${partNumber}`,
            blob
        )

        console.log(result)

        uploadSpeed.currentUploadSize = uploadSpeed.currentLoadedSize + result.loaded;

        uploadSpeed.currentLoadedSize += blob.size

        return result.data
    }

    return (
        <section className="container">
            <Container {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p>Drag  drop some files here, or click to select files</p>
            </Container>
            <aside>
                <h4>Files</h4>
                <ul>{files}</ul>
            </aside>

            <Button variant='outlined' onClick={() => upload()} >Upload</Button>
            <a
                href={downloadURL}
            >
                <h3>{downloadURL}</h3>
            </a>

        </section>
    );
}
