import './App.css';
import { Routes, Route, HashRouter } from 'react-router-dom'
import UploadPage from './pages/uploadPage'


function App() {
  return (
    <div className="App">
      <HashRouter >

        <Routes base>

          <Route exact path={`/`} element={<UploadPage />} />
          <Route path={`*`} element={<UploadPage />} />

        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
